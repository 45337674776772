import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "HACK Night poster design",
  "featuredImage": "images/hack_night.jpg",
  "coverAlt": "Hack Night poster",
  "description": "Graphic Design for Kamloops Innovation's Hack Night",
  "date": "2015-06-03",
  "dateModified": "2015-06-03",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Graphic Design"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`While freelancing at Dansk Design group, under supervision, I designed this poster which is still being used for many events at Kamloops innovation.`}</p>
    <p>{`I take full responsibility for the 4 (instead of an A) in H4CK Night :D`}</p>
    <h2 {...{
      "id": "software-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#software-used",
        "aria-label": "software used permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Used`}</h2>
    <p>{`Adobe Illustrator`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "663px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/83304296e24afb67786654adb12c2bf6/8b285/hack_night.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "154.44444444444446%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAABQAEAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABaORwCjckLUzeUI2SL//EAB0QAAICAgMBAAAAAAAAAAAAAAECAAMRFAQSEyP/2gAIAQEAAQUCsPWv2Ouh7JYCazSddBhMfTEEbkVLF5iFtlJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAECAQE/AcGL/8QAHxAAAgEEAgMAAAAAAAAAAAAAAAERAhASMSFBUWGB/9oACAEBAAY/AqmJ5cyJ+SpLcGOPciXo13ed/CIdv//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFBYXExUYH/2gAIAQEAAT8hcktDEbQjDZOEwzzsFUpjkP4iNFICKXt39ymYCoTu4gFTvc7Z/9oADAMBAAIAAwAAABCExk//xAAXEQEAAwAAAAAAAAAAAAAAAAARASBh/9oACAEDAQE/EIDaf//EABkRAAMAAwAAAAAAAAAAAAAAAAABERBRcf/aAAgBAgEBPxB24eg4P//EAB8QAQACAgICAwAAAAAAAAAAAAERIQAxQVFxgZGh8P/aAAgBAQABPxBQS6DvjEru24yBO/2sAJUEXdmE/InnFYs+wFSaKe7D1hnEgNCF5cBqKm1l3FBz3WM1PrBUPkx7J4JJWdWZa/NkgO8E0+w5/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Kamloops Innovation - Hack Night",
              "title": "Kamloops Innovation - Hack Night",
              "src": "/static/83304296e24afb67786654adb12c2bf6/8b285/hack_night.jpg",
              "srcSet": ["/static/83304296e24afb67786654adb12c2bf6/543cd/hack_night.jpg 360w", "/static/83304296e24afb67786654adb12c2bf6/8b285/hack_night.jpg 663w"],
              "sizes": "(max-width: 663px) 100vw, 663px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Kamloops Innovation - Hack Night`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      